import packageJson from '../../package.json';

const apiDomain = 'staging-api.mediajungle.eu';
const secure    = true;

const apiUrl             = 'http' + (secure ? 's' : '') + '://' + apiDomain;
export const environment = {
  production:            false,
  environment:           'staging',
  sentryEnabled:         true,
  version:               packageJson.version,
  apiDomain,
  apiUrl,
  apiGraphqlUrl:         apiUrl + '/graphql',
  appUrl:                'http' + (secure ? 's' : '') + '://' + apiDomain,
  networkOnly:           true,
  skipAnimation:         false,
  accessAllExercises:    false,
  cryptoKey:             '9ZQhjw79oAAYstN',
  ablyApiKey:            '2NAWng.-_we4w:egASY7PwKRcsz4p9_fQg2qus9VydIu_Cuqo08BaxXl0',
  readspeakerEnabled:    true,
  elevenlabsApiKey:      'b3e91da3aa3b598940d6abfcc90f0b62',
  elevenlabsSpeed:       1,
  elevenlabsOptions:     {
    'stability':         0.7,
    'similarity_boost':  0.6,
    'style':             0,
    'use_speaker_boost': true
  }
};
